<template>
  <section id="ProDetail">
      <div style="width:220px;">
        <!-- <div  style="background:red;color:white">产品分类</div> -->
        <div  v-for="(item,index) in tabs" :key="index"  class="product" @click="choose(item)">{{item.text}}</div>
      </div>
      <div style="width:100%;margin-left:20px">
        <span style="height:50px;border-bottom: 2px solid red;display: flex;align-items: center;width:fit-content">{{detail.text }}</span>
        <div v-for="item in list" :key="item">
        <article class="body">
              <figure v-for="i in item.t" :key="i">
                  <img class="pic" :src="i.url" alt="" @click="$emit('E_detail',i)">
                  <figcaption style="line-height:200%;padding-top:.08rem">{{i.t1}}</figcaption>
              </figure>
              </article>
      </div>
      </div>
  </section>
</template>

<script>
import { reactive, toRefs } from '@vue/reactivity'
import { state05} from '../assets/data/index.js'
export default {
  setup(){
    const state = reactive({
      list:state05,
      detail:{},
      tabs:[
        {
          text:"智能售货机",
          index:"1"
        },
        {
          text:"多功能自助机",
          index:"2"
        },
        {
          text:"物联网设备",
          index:"3"
        },
        {
          text:"格子柜",
          index:"4"
        },
        {
          text:"取餐机",
          index:"5"
        },
        {
          text:"广告机",
          index:"6"
        },
        {
          text:"消费类电子",
          index:"7"
        }
      ],
    })
    console.log(state.list)
    const showConter = function(e){
            state.detail = e
            console.log(state.detail);
        }
    const choose = function(e){
      state.detail = e
    }
    return {
      ...toRefs(state),
      showConter,
      choose
    }
  }
}
</script>
.
<style scoped lang="stylus">
#ProDetail
  background #fff
  padding: 0 2.5rem
  display: flex
  margin-top: 10px
.title
  text-align center
  font-size .14rem
  cite 
    padding-top .3rem
    display block
    font-style normal
  hr 
    border: none;
    height: .01rem;
    transform scaleY(1.3)
    background-color: #999;
    opacity .6
    width 1rem
    position relative
  hr:after
    content ""
    position absolute
    width .24rem
    height .04rem
    background blue
    top 50%
    left 50%
    transform translate(-50%,-50%)
  dfn
    font-size .1rem
    display block
    padding-top .06rem
    font-style normal
.body
  display flex
  flex-wrap wrap
  justify-content space-around
  margin-top: 20px
  figure
    text-align center
    font-size 14px
    position relative
    
    .pic
      height 180px
      width 180px
    
.product
      color: black
      height: 50px
      display: flex
      align-items: center
      padding-left: 10px
      font-size: 12px
      border: 1px solid #f5f5f5
      width: 220px
.product:hover
      color: red
      background: white
      cursor: pointer
      border: 1px solid red
</style>
