<template>
  <div class="test">
    <h2>store.count = {{testState.count}}</h2>
  </div>
</template>

<script>
import Store from '../store';
import { inject, onMounted, reactive, toRefs } from 'vue';
export default {
  name: 'Test',
  setup(){
    const state = reactive({
      name,
      ...inject(Store.testStore)
    })
    onMounted (async ()=>{
    })
    return { 
      ...toRefs(state) ,
    }
  }
}
</script>
