<template>
  <div class="container">
    <Header  @tabclickevent="HeaderTabClick" />
    <!-- <Swiper /> -->
    <ProDetail  ref="detail" /> 
    <!-- <Classic /> -->
    <!-- <PartnerNew /> -->
    <!-- <ProfessionInfo /> -->
    <!-- <button @click="add()">Store add + 1</button>
    <button @click="logInject()">Route to test.vue</button> -->
    <!-- <p>{{testState.count}}</p> -->
    <FooterNew />

  </div>
</template>

<script>
// @ components
import Header from '@/components/Header.vue'
import FooterNew from '@/components/FooterNew.vue'
import ProDetail from '@/components/ProDetail.vue'
// hooks
// import pageroll from '@/hooks/pageroll.js'

import Store from '../store';
import { onMounted, reactive , ref, toRefs , inject } from 'vue';

import { useRouter } from "vue-router";
export default {
  name: 'index',
  // components: {
  //   Header,Footer,Swiper,Prdoucts,Classic,Partner,About,
  //   Detail
  // },
  components: {
    Header,FooterNew,ProDetail
  },
  
  setup(){
    // const {RollTo,HeaderCurrentTab} = pageroll() // 页面滚动和选中
    const router = useRouter()
    console.log(router.currentRoute.value.query)
    // const pro_choose = route.params
    // console.log(pro_choose)
    // const $R = useRouter()
    const state = reactive({
      ...inject(Store.testStore),
      detail :{}
    })
    // Header
    function HeaderTabClick(){
      router.push({
        path: '/index',
        name: 'Index',
      })
    }
    // Prdoucts
    const detail = ref()
    function showDetail(){
      detail.value.showConter(router.currentRoute.value.query)
    }
    // detail.value.showConter(router.currentRoute.value.query)

    onMounted( ()=>{
      showDetail()
    }) 

   
    return { 
      ...toRefs(state) ,
      // Header
      HeaderTabClick,
      // Prdoucts
      detail,
      showDetail,

    }
  }
}
</script>

<style lang="stylus" scoped>
.container
  min-height 100vh
  position relative
  overflow-y scroll
  display block
  box-sizing border-box

</style>
